import './App.css';
import Header from './components/Header';

function App() {
  return (
    <div className="flex flex-col items-center justify-between w-full h-full text-white bg-gray-800 App">
      
      <div className="flex flex-col items-center menu bg-slate-600 w-full h-[200px]">

        <Header />
        
      </div>
      <div className="flex flex-col items-center justify-center w-full h-full main-content bg-[#f8f0ea]">
        <h1  className="text-3xl text-white" style={{  textShadow: '2px 2px 7px black'}}>We are currently under construction</h1>
        {/* <img src="/logo.png" alt='logo' style={{  background: 'linear-gradient(45deg, white, transparent)'}} className='max-w-[300px]'/> */}
        <section className="text-white notice"  style={{  textShadow: '2px 2px 7px black'}}>
          <p>Please try us again soon.</p>
        </section>

      </div>
      <footer className="flex footer w-full h-[80px] bg-black flex-col items-center justify-center">
        <p>© Copyright 2023 Ruelea.com</p>
        <span>all rights reserved</span>
      </footer>

    </div>
  );
}

export default App;
